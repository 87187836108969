<template>
  <Teleport to="#app">
    <UiModal
      :title="titleModal"
      close-button-name="Close"
      :width="'100%'"
      :max-width="'500px'"
      :height="'500px'"
      :is-auto-height="true"
      @close="close()"
    >
      <div :class="'frm ' + cat">
        <div class="search">
          <img v-if="brand_avatar" data-not-lazy :src="brand_avatar" class="logo_selected" alt="">

          <div class="inpvl">
            <GeneralSearchInput
              @search="chooseKeyword"
              :val="keyword"
              :cl="''"
              :placeholder="inputPlaceholder"
            />
          </div>

          <!-- <div class="back" v-if="brand_id" @click="back">
            <i class="icon-angle-left"/>
            Back
          </div> -->
        </div>

 

        <div v-if="cat === ECategoryType.Lamp && props.type == 'product' && false" class="filter">
          <GeneralSearchInputNumber
            :max="100000"
            :min="1"
            :placeholder="$t('watt')"
            :step="10"
            v-model="filters.power"
            @change="onChangeLampPower"
          />

          <div class="types_wrap">
            <div class="types_wrap__label">{{ $t('type') }}</div>

            <div class="types">
              <div
                v-for="(item, index) in allProductsLists.lampTypeList"
                :key="index"
                :class="'it' + (item.active ? ' active ' : '')"
                @click="chooseCategory('lampTypeList', item, EToggleClassType.Single, index)"
              >
                {{ $t(item.name) }}
              </div>
            </div>
          </div>
        </div>

        <div class="list">

          <UiButton
            :name="'Add Custom'"
            :type="'secondary wide'"
            @click="addCustom"
            />   

          <UiEmpty 
            v-if="!search_data?.items?.length && !is_loading"
            class="empty" 
            :text="'Empty result, please use custom name'"
            />


          <div class="list recommended" v-if="search_data?.items_recommended?.length">
                        
            <div class="title">Popular</div> 

            <template v-for="item in search_data?.items_recommended">
              <div class="item" @click="chooseItem(item)">
                <div class="logo">
                  <img
                    data-not-lazy
                    class="lg"
                    :src="'/images/medium/' + item.id + '.svg'"
                    alt=""
                    v-if="cat === ECategoryType.Medium"
                  >

                  <img
                    data-not-lazy
                    class="lg"
                    src="/images/setup_diary/setup_diary_icons-18.svg"
                    alt=""
                    v-else-if="item?.avatar < 1"
                  >

                  <img
                    data-not-lazy
                    class="lg"
                    :src="item?.avatar ? item.avatar : item.icon"
                    alt=""
                    v-else
                  >
                </div>

                <div class="name">
                  <div v-if="cat === ECategoryType.Lamp && item.type !== 'brand' " class="power_type">
                    <span class="type" v-if="item.props?.type">
                      {{ $constants.typeLightShort[item.props.type] ? $t($constants.typeLightShort[item.props.type]) : '' }}
                    </span>
                    <template v-if="item.props?.type && item.props?.power"> / </template>
                    <span class="power" v-if="item.props?.power">{{ item.props.power }}W</span>
                  </div>

                  <template v-if="item.lng_key">
                    {{ item.lng_key ? $t(item.lng_key) : ''  }}
                  </template>
                  <template v-else-if="item.brand_name && item.name">
                    {{ item.brand_name }} - {{ item.name }}
                  </template>
                  <template v-else>
                    {{ item.brand_name }} {{ item.name }}
                  </template>
                </div>
              </div>
            </template>
          </div>

          <div class="title" v-if="search_data?.items_recommended?.length">All</div> 
            
          <template v-for="item in search_data?.items">
            <div class="item" @click="chooseItem(item)">
              <div class="logo">
                <img
                  data-not-lazy
                  class="lg"
                  :src="'/images/medium/' + item.id + '.svg'"
                  alt=""
                  v-if="cat === ECategoryType.Medium"
                >

                <img
                  data-not-lazy
                  class="lg"
                  src="/images/setup_diary/setup_diary_icons-18.svg"
                  alt=""
                  v-else-if="item?.avatar < 1"
                >

                <img
                  data-not-lazy
                  class="lg"
                  :src="item?.avatar ? item.avatar : item.icon"
                  alt=""
                  v-else
                >
              </div>

              <div class="name">
                <div v-if="cat === ECategoryType.Lamp && item.type !== 'brand' " class="power_type">
                  <span class="type" v-if="item.props?.type">
                    {{ $constants.typeLightShort[item.props.type] ? $t($constants.typeLightShort[item.props.type]) : '' }}
                  </span>
                  <template v-if="item.props?.type && item.props?.power"> / </template>
                  <span class="power" v-if="item.props?.power">{{ item.props.power }}W</span>
                </div>

                <template v-if="item.lng_key">
                  {{ item.lng_key ? $t(item.lng_key) : ''  }}
                </template>
                <template v-else-if="item.brand_name && item.name">
                  {{ item.brand_name }} - {{ item.name }}
                </template>
                <template v-else>
                  {{ item.brand_name }} {{ item.name }}
                </template>
              </div>
            </div>
          </template>

          <client-only>
            <InfinityScroll
              v-if="!is_ended"
              :is-loading="is_loading"
              ref="el_infinity_scroll"
            />
          </client-only>
        </div>
      </div>
    </UiModal>
  </Teleport>
</template>

<script setup lang="ts">
import { useIntersectionObserver, watchArray } from '@vueuse/core'
import { ECategoryType, ELampFaza } from "~/types/Enums";

interface IListItem {
  active: boolean
  name: string
}

enum EToggleClassType {
  Multi = 1,
  Single = 2
}

const emits = defineEmits(['close', 'choose', 'customize', 'prechoose'])
const { t } = useI18n()
const { $api, $constants } = useNuxtApp()

const props = defineProps({
  showed: Boolean,
  cat: String,
  titleModal: String,
  inputPlaceholder: String,
  faza: ELampFaza,
  type: {
    type: String,
    default: 'brand'
  },
  refId: {
    type: Number,
    default: null
  },
  brandId: {
    type: Number,
    default: null
  },
  brandName: {
    type: String,
    default: null
  },
  brandAvatar: {
    type: String,
    default: null
  }
});

const airConditioners = reactive<{
  power: number;
  coolingCapacity: number;
}>({
  power: 1,
  coolingCapacity: 1
})

const brand_id = ref(props.brandId);
const brand_name = ref(props.brandName);
const brand_avatar = ref(props.brandAvatar);
const co2Generators = reactive<{
  liquidPropaneCapacity: number;
  naturalGasCapacity: number;
}>({
  liquidPropaneCapacity: 1,
  naturalGasCapacity: 1
})
const controllers = reactive({
  type: 0,
  sensors: 0,
  controls: 0
})
const dripSystems = reactive({
  pumpCapacity: 0,
  waterTankCapacity: 0
})
const el_infinity_scroll = ref(null)
const ventilationFans = reactive({
  dia: 0,
  airCapacity: 0
})
const airFilters = reactive<{
  dia: number;
  width: number;
  airFlowCapacity: number;
}>({
  dia: 1,
  width: 1,
  airFlowCapacity: 1
})
const growBoxSize = reactive<{
  depth: number;
  height: number;
  width: number;
}>({
  depth: 1,
  height: 1,
  width: 1,
})
const growBoxSquare = computed(() => {
  const itemName = allProductsLists.growBoxesList.find(item => item.active).name
  const res = itemName === 'universal_type_size_cm' ? 'm' : 'ft'

  return {
    result: (growBoxSize.width * growBoxSize.depth).toFixed(2) / 100,
    name: res
  }
})
const hydroponicSystems = reactive<{ pumpCapacity: number; waterTankVolume: number; includesSensorsControllers: number; }>({
  pumpCapacity: 1,
  waterTankVolume: 1,
  includesSensorsControllers: 1
})
const allProductsLists = reactive({
  airFiltersList: $constants.typeAirFilters as IListItem[],
  controllersControls: $constants.typeControllersControls as IListItem[],
  controllersSensors: $constants.typeControllersSensors as IListItem[],
  controllersTypes: $constants.typeControllersTypes as IListItem[],
  dripSystems: $constants.typeDripSystems as IListItem[],
  growBoxesList: $constants.typeBoxSizes as IListItem[],
  hydroponicSystemsList: $constants.typeHydroponicSystems as IListItem[],
  lampTypeList: $constants.typeLampType as IListItem[]
})
const id = ref(null);
const is_ended = ref(false);
const keyword = ref('');
const filters = ref({});
const limit = ref(30);
const name = ref(null);
const power = ref(null);
const start = ref(0);
const type = ref(props.type);
const isActive = ref(false);

const dripSystemTitle = computed(() => {
  return allProductsLists.dripSystems[0].active ? 'universal_type_liter' : 'universal_type_gallon'
})

const back = () => {
  type.value = 'brand';
  clear();
};

const clear = () => {
  id.value = null;
  name.value = null;
  brand_id.value = null;
  brand_name.value = null;
  brand_avatar.value = null;
  power.value = null;
  filters.value.type = null;
  start.value = 0;
  is_ended.value = false;
};

const close = () => {
  clear();
  emits('close');
};

const chooseCategory = (list: string, item: any, categoryType: EToggleClassType, index: number) => {
  start.value = 0
  type.value = 'product'

  if (categoryType === EToggleClassType.Single) {
    allProductsLists[list].forEach((el) => {
      el.active = el.name === item.name
    })
  }

  if (categoryType === EToggleClassType.Multi) {
    item.active = !item.active
  }

  if (filters.value.type === item)
    filters.value.type = 0
  else
    filters.value.type = index + 1
}

const onChangeLampPower = () => {
  start.value = 0
  type.value = 'product'
}

const changePower = (vl) => {
  start.value = 0;
  filters.value.power = vl;
  type.value = 'product';
}

const toggleClass = (list: string, item: any, type: EToggleClassType) => {
  if (type === EToggleClassType.Single) {
    allProductsLists[list].forEach((el) => {
      el.active = el.name === item.name
    })
  }

  if (type === EToggleClassType.Multi) {
    item.active = !item.active
  }
}

const chooseItem = (item) => {
  if (item.type === 'brand') {
    type.value = 'product';
    brand_id.value = item.brand_id;
    brand_name.value = item.brand_name;
    brand_avatar.value = item.avatar ? item.avatar : item.icon;
    keyword.value = '';
    start.value = 0;
    // eventPrechoose(item);
    eventChoose();
  } else if (item.type === 'product') {
    id.value = item.id;
    name.value = item.name;
    brand_id.value = item.brand_id;
    brand_name.value = item.brand_name;
    brand_avatar.value = item.avatar ? item.avatar : item.icon;
    filters.value.type = item.props?.type;
    filters.value.power = item.props?.power;
    keyword.value = '';
    eventChoose();
  } else {
    id.value = item.id;
    name.value = item.name;
    brand_id.value = item.brand_id;
    brand_name.value = item.brand_name;
    brand_avatar.value = item.avatar ? item.avatar : item.icon;
    filters.value.type = item.props?.type;
    filters.value.power = item.props?.power;
    keyword.value = '';
    start.value = 0;
    eventChoose();
  }
}

const eventPrechoose = (item) => {
  emits('prechoose', item);
}

const eventChoose = () => {
  const item = {
    ref_id: props.refId,
    is_remove: 0,
    id: id.value,
    name: name.value,
    category: props.cat,
    item_brand: {
      id: brand_id.value,
      name: brand_name.value,
      logo_s: brand_avatar.value
    },
    props: {}
  }

  if (props.cat === ECategoryType.Medium) {
    item.value = 0
  }

  if (props.cat === ECategoryType.Lamp) {
    item.props.type = parseInt(filters.value.type)
    item.props.power = parseInt(filters.value.power)
    item.props.faza = parseInt(props.faza)
  }

  if (props.cat === ECategoryType.Seed) {
    item.props.cnt = 1
    item.props.type = 1
  }

  if (props.cat === ECategoryType.Nutrient) {
    item.unit = useAuth().getNutrient()
  }

  emits('choose', item);
}

const chooseKeyword = (val) => {
  keyword.value = val;
  start.value = 0;
};

const calcProps = computed(() => {
  let pr = [];

  if (filters.value.power)
    pr.push('power:' + filters.value.power);

  if (filters.value.type)
    pr.push('type:' + filters.value.type);

  return pr.join(',');
})

const loadResults = async () => {
  if (props.cat === ECategoryType.Medium) {
    let filtered = {}
    filtered.items = [];

    if (keyword.value.length > 0) {
      filtered.items.push({
        id: 7,
        name: keyword.value?.length ? keyword.value : 'Other'
      })
    } else {
      for (let i in $constants.medium) {
        let tr = $constants.medium[i]
        let name = tr ? t(tr) : ''

        filtered.items.push({
          id: i,
          name: name,
        });
      }
    }

    return filtered
  } else {
    const item = {
      query: keyword.value,
      category: props.cat,
      type: type.value === 'brand' && (calcProps.value || keyword.value) ? 'brandproduct' : type.value,
      brand_id: brand_id.value,
      start: start.value,
      limit: limit.value,
      props: calcProps?.value
    }

    // TODO | front | remove it when category lamp will work from ECategoryType
    // should be fixed from back
    if (props.cat === ECategoryType.Lamp) {
      item.category = 'lamp'
    }

    return await $api.getSearchBrandProduct(item);
  }
}

const {
  pending: is_loading,
  data: search_data
} = useLazyAsyncData('search_data_' + Math.random() , async () => await loadResults())


const addCustom = function(){  
  emits('close');
  emits('customize');
}

watchArray([
  start,
  keyword,
  type,
  filters,
  growBoxSquare,
  allProductsLists
], async ([new_start], added, removed) => {
  console.log('watcher');

  if (new_start === 0){
    search_data.value.items = [];
    search_data.value.items_recommended = [];
  }

  is_loading.value = true;

  var dt = await loadResults();

  if (new_start === 0)
    is_ended.value = false;

  if (dt?.items?.length === 0)
    is_ended.value = true;

  if (dt?.items?.length > 0){
    search_data.value.items = [...search_data.value.items, ...dt?.items]
    search_data.value.items_recommended = dt.items_recommended
  }

  is_loading.value = false;
}, {deep: true})

useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');

      if (props.cat === ECategoryType.Medium) return

      if (!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)
</script>

<style scoped lang="scss">
.square {
  &__title {
    white-space: nowrap;
  }
}

// form
.frm {
  height: 100%;
  min-height: 390px;

  .back {
    cursor: pointer;
    flex-shrink: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  // filter
  .filter {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
    row-gap: 20px;
    width: 100%;

    // filter power
    .power {
      display: flex;
      width: calc((100%  / 3) - 7px);
      height: 38px;

      &.disabled {
        .btns {
          background-color: #f1f1f1;
          color: #494949;
          width: 40px;
        }
      }

      input {
        padding: 10px;
        text-align: center;
        width: calc(100% - 80px);
        max-width: 100px;
      }

      .btns {
        background-color: var(--un-primary-back-color);
        color: white;
        text-align: center;
        line-height: 3rem;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        * {
          margin-right: 0;
          cursor: pointer;

          &:hover {
            opacity: .8;
          }
        }

        &.bl {
          border-radius: 33px 0 0 33px;
        }

        &.br {
          border-radius: 0 33px 33px 0;
        }
      }
    }

    .types {
      .it {
        @media (max-width: 768px) {
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  .title{

    font-weight: 700;
    margin: 10px 0;
  }

  .list {
    overflow-y: auto;
    max-height: 330px;
    overscroll-behavior: contain;

    .item {
      padding: 4px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      min-height: 35px;

      .logo {
        height: 35px;
        margin-right: 1rem;
        width: 60px;

        .lg {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      .name {
        margin-right: 5px;
      }

      .sec_name {
        margin-left: 5px;
      }
    }
  }

  .list.recommended {
    margin-bottom: 1rem;
    
    overflow-y: hidden;
    max-height: none;
  }

  .power_type {
    width:100%;
    color: var(--un-text-color);
    font-weight: 700;
  }

  .search {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .inpvl {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .logo_selected {
      width: 25px;
      margin-right: 10px;
      height: 25px;
      border: 0;
      object-fit: contain;
      flex-shrink: 0;
    }
  }

  // filter type lamp
  .types {
    display: flex;
    align-items: center;

    .it {
      padding: .6rem 1rem;
      background-color: var(--un-element-color-gray);
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      &:first-child {
        border-radius: 30px 0 0 30px;
      }

      &:last-child {
        border-radius: 0 30px 30px 0;
      }

      &.active {
        background-color: var(--un-primary-back-color);
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    height: calc(100dvh - 140px);

    .filter {
      .power {
        width: 100%;
        margin-right: 0;

        input {
          max-width: none;
        }
      }
    }

    .list {
      max-height: calc(100% - 70px);
    }

    &.lamp {
      .list {
        max-height: calc(100% - 100px);
      }
    }
  }
}

.types_wrap {
  position: relative;

  &__label {
    font-size: .8em;
    padding: 0 0 .2em;
    opacity: .7;
    bottom: 100%;
    left: 0;
    position: absolute;
  }
}
</style>